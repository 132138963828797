<template>
    <div class="row">
        <div class="col-md-12 summary">
            <p class="mr-10">Order : <b>{{ summary ? summary.total_order : 0 }}</b></p>
            <p class="mr-10">(Completed: <b>{{ summary ? summary.completed : 0 }}</b>, Incompleted: <b>{{ summary ? summary.incompleted : 0 }}</b>)</p>
            <p class="mr-10">PR: <b>{{ summary ? summary.total_partial_return : 0 }}, </b>  EX: <b>{{ summary ? summary.total_total_exchanged : 0 }}</b>,</p>
            <p class="mr-10">Amount : <b>{{ summary ? summary.total_amount : 0 }}</b></p>
            <p class="mr-10">Collected : <b>{{ summary ? summary.total_collected : 0 }}</b></p>
            <p class="mr-10">Service Charge : <b>{{ summary ? summary.total_service_charge : 0 }}</b></p>
            <p class="mr-10">COD Charge : <b>{{ summary ? summary.total_cod : 0 }}</b></p>
            <p class="mr-10">Area Charge : <b>{{ summary ? summary.total_area_charge : 0 }}</b></p>                                        
            <p class="mr-10">Weight Charge : <b>{{ summary ? summary.total_weight_charge : 0 }}</b></p>
            <p class="mr-10">Return Charge : <b>{{ summary ? summary.total_return_charge : 0 }}</b></p>
            <p class="ml-20">
                <slot v-if="loading">
                    <div class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
                </slot>
            </p>
        </div>
    </div>
</template>
<script>
    import config from '../../config'
    export default {
        name: 'Summary',
        props: ['search'],
        data() {
            return {
                loading: false,
                completedStatusIds: [14,15,16,19,20,24,25,26,31],
                inCompletedStatusIds: [1,2,3,4,5,6,7,8,9,10,11,12,13,17,18,21,22,23,27,28,30],
                summary: {
                    total_order: 0,
                    completed: 0,
                    incompleted: 0,
                    total_partial_return: 0, 
                    total_total_exchanged: 0, 
                    total_amount: 0,
                    total_collected: 0,
                    total_service_charge: 0,
                    total_cod: 0,
                    total_area_charge: 0,
                    total_weight_charge: 0,
                    total_return_charge: 0
                }
            }
        },
        computed : {
            stateSummary: function () {
                return this.$store.state.summary
            },            
            authUser () {
                return this.$store.state.authUser
            }
        },
        created () {
            if (this.stateSummary) {
                this.loadSummary()
            }
        },
        watch: {
            'stateSummary': function () {
                this.loadSummary()
            }
        },
        methods: {
            async loadSummary() {
                this.loading = true
                const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { destination_hub_id: this.authUser.hub_id }) : this.search 
                const response = await config.getData('order/summary', params)
                this.loading = false
                if (response.success) {
                    // this.summary = this.getSummary(response.data)
                    this.summary = response.summary
                } else {
                    this.summary = {
                        total_order: 0,
                        completed: 0,
                        incompleted: 0,
                        total_partial_return: 0, 
                        total_total_exchanged: 0, 
                        total_amount: 0,
                        total_collected: 0,
                        total_service_charge: 0,
                        total_cod: 0,
                        total_area_charge: 0,
                        total_weight_charge: 0,
                        total_return_charge: 0
                    }
                }
                this.$store.dispatch('summarySearch', false)
            },
            getSummary (datas) {
                let totalOrder = 0
                let totalPrice = 0
                let totalCompleted = 0
                let totalInCompleted = 0
                let totalPr = 0
                let totalEx = 0
                let totalCollected = 0
                let totalCod = 0
                let totalServiceCharge = 0
                let totalAreaCharge = 0
                let totalWeightCharge = 0
                let totalReturnCharge = 0

                datas.map(item => {
                    totalOrder += (item.pr == 2 && item.ex == 2) ? 1 : 0
                    totalCompleted += (this.completedStatusIds.includes(item.status) && item.pr == 2 && item.ex == 2) ? 1 : 0
                    totalInCompleted += (this.inCompletedStatusIds.includes(item.status) && item.pr == 2 && item.ex == 2) ? 1 : 0
                    totalPr += parseInt(item.pr) == 1 ? 1 : 0
                    totalEx += parseInt(item.ex) == 1 ? 1 : 0
                    totalPrice += (item.pr == 2 && item.ex == 2) ? parseFloat(item.price) : 0
                    totalCollected += (item.pr == 2 && item.ex == 2) ? parseFloat(item.collected) : 0
                    totalCod += (item.amount_receive != 0 && item.pr == 2 && item.ex == 2) ? parseFloat(item.cod) : 0
                    totalServiceCharge += (item.pr == 2 && item.ex == 2) ? parseFloat(item.service_charge) : 0
                    totalAreaCharge += (item.pr == 2 && item.ex == 2) ? parseFloat(item.area_charge) : 0
                    totalWeightCharge += (item.pr == 2 && item.ex == 2) ? parseFloat(item.weight_charge) : 0
                    totalReturnCharge += (item.pr == 2 && item.ex == 2) ? parseFloat(item.return_charge) : 0
                })

                const tmpSummary = { 
                    'total_order': totalOrder, 
                    'completed': totalCompleted, 
                    'incompleted': totalInCompleted, 
                    'total_partial_return': totalPr, 
                    'total_exchanged': totalEx, 
                    'total_amount' : totalPrice.toFixed(2), 
                    'total_collected': totalCollected.toFixed(2), 
                    'total_cod': totalCod.toFixed(2), 
                    'total_service_charge': totalServiceCharge.toFixed(2), 
                    'total_area_charge': totalAreaCharge.toFixed(2), 
                    'total_weight_charge': totalWeightCharge.toFixed(2),
                    'total_return_charge': totalReturnCharge.toFixed(2)
                }
               return Object.assign(this.summary, tmpSummary)
            }
        }
    }
</script>