<template>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered table-striped table-sm">
                <thead>
                    <tr class="text-center">
                        <td>Total SLA Missed: <strong>{{ summary ? summary.total_sla_missed : 0 }}</strong></td>
                        <td @click="sendData(1)">Total SLA Missed (ISD): <strong style="cursor:pointer">{{ summary ? summary.total_sla_missed_isd : 0 }}</strong></td>
                        <td @click="sendData(2)">Total SLA Missed (OSD): <strong style="cursor:pointer">{{ summary ? summary.total_sla_missed_osd : 0 }}</strong></td>
                        <td @click="sendData(3)">Total SLA Missed (SA): <strong style="cursor:pointer">{{ summary ? summary.total_sla_missed_sa : 0 }}</strong></td>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    export default {
        name: 'Create',
        props: ['merchant_id'],
        data() {
            return {
                loading: false,
                summary: null
            }
        },
        created () {
            this.loadData()
        },
        methods: {
            async loadData() {
                this.loading = true
                const searchData = { merchant_id: this.merchant_id }
                const result = await config.getData('/order/sla', searchData)   
                this.loading = false             
                if (result.success) {  
                    this.summary = result.data
                } else {
                    this.summary = null
                }
            },
            sendData(param) {
                this.$emit('sla', {
                    sla_type: 'delivery_sla',
                    coverage_area_id: param,
                    merchant_id: this.merchant_id,
                    total_sla_missed: this.summary.total_sla_missed,
                    total_sla_missed_isd: this.summary.total_sla_missed_isd,
                    total_sla_missed_osd: this.summary.total_sla_missed_osd,
                    total_sla_missed_sa: this.summary.total_sla_missed_sa,
                    sla_isd_date: this.summary.sla_isd_date,
                    sla_osd_date: this.summary.sla_osd_date,
                    sla_sa_date: this.summary.sla_sa_date
                })
            }
        }
    }
</script>
